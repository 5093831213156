<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/postcode-group">
          <b-button
            variant="primary"
            size="md"
          >
            <span class="align-middle">Back</span>
          </b-button>
        </router-link>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-card title="Deleted Postcode Groups">
          <b-row class="my-1">
            <b-col
              cols="6"
            >
              <b-form-group
                class="mb-0 align-items-center"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="perPageSelect"
                label="Per page"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              class="ml-auto"
            >
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            ref="postcodeGroupTable"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            :busy.sync="isBusy"
            :items="items"
            :filter="filter"
            selectable
            select-mode="single"
          >
            <template #table-busy>
              <div class="text-center text-success my-2">
                <b-spinner class="align-middle" />
                <strong> Loading...</strong>
              </div>
            </template>
            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
              </b-form-checkbox>
            </template>
            <template #row-details="row">
              <b-row>
                <b-col
                  v-if="row.item.weekdays[0].isSelected"
                  md="3"
                  sm="6"
                  cols="6"
                  class="mb-1"
                >
                  <strong>Monday: </strong>
                  <p class="m-0">
                    Repeated: {{ row.item.weekdays[0].repeatWeek }}
                  </p>
                  <p>Start Date: {{ row.item.weekdays[0].startDate }}</p>
                </b-col>
                <b-col
                  v-if="row.item.weekdays[1].isSelected"
                  md="3"
                  sm="6"
                  cols="6"
                  class="mb-1"
                >
                  <strong>Tuesday: </strong>
                  <p class="m-0">
                    Repeated: {{ row.item.weekdays[1].repeatWeek }}
                  </p>
                  <p>Start Date: {{ row.item.weekdays[1].startDate }}</p>
                </b-col>
                <b-col
                  v-if="row.item.weekdays[2].isSelected"
                  md="3"
                  sm="6"
                  cols="6"
                  class="mb-1"
                >
                  <strong>Wednesday: </strong>
                  <p class="m-0">
                    Repeated: {{ row.item.weekdays[2].repeatWeek }}
                  </p>
                  <p>Start Date: {{ row.item.weekdays[2].startDate }}</p>
                </b-col>
                <b-col
                  v-if="row.item.weekdays[3].isSelected"
                  md="3"
                  sm="6"
                  cols="6"
                  class="mb-1"
                >
                  <strong>Thursday: </strong>
                  <p class="m-0">
                    Repeated: {{ row.item.weekdays[3].repeatWeek }}
                  </p>
                  <p>Start Date: {{ row.item.weekdays[3].startDate }}</p>
                </b-col>
                <b-col
                  v-if="row.item.weekdays[4].isSelected"
                  md="3"
                  sm="6"
                  cols="6"
                  class="mb-1"
                >
                  <strong>Friday: </strong>
                  <p class="m-0">
                    Repeated: {{ row.item.weekdays[4].repeatWeek }}
                  </p>
                  <p>Start Date: {{ row.item.weekdays[4].startDate }}</p>
                </b-col>
                <b-col
                  v-if="row.item.weekdays[5].isSelected"
                  md="3"
                  sm="6"
                  cols="6"
                  class="mb-1"
                >
                  <strong>Saturday: </strong>
                  <p class="m-0">
                    Repeated: {{ row.item.weekdays[5].repeatWeek }}
                  </p>
                  <p>Start Date: {{ row.item.weekdays[5].startDate }}</p>
                </b-col>
                <b-col
                  v-if="row.item.weekdays[6].isSelected"
                  md="3"
                  sm="6"
                  cols="6"
                  class="mb-1"
                >
                  <strong>Sunday: </strong>
                  <p class="m-0">
                    Repeated: {{ row.item.weekdays[6].repeatWeek }}
                  </p>
                  <p>Start Date: {{ row.item.weekdays[6].startDate }}</p>
                </b-col>
              </b-row>
              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button>
            </template>
            <template #cell(ID)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(group)="data">
              {{ data.value }}
            </template>
            <template #cell(postal_code_group_keys_with_trashed)="data">
              {{ arrayToString(data.value) }}
            </template>
            <template #cell(actions)="data">
              <div class="d-flex">
                <b-button
                  variant="flat-success"
                  class="btn-icon"
                  @click="restore(data.item)"
                >
                  Restore
                </b-button>

                <b-button
                  variant="flat-danger"
                  class="btn-icon"
                  @click="deletePostcodeGroupPermanently(data.item)"
                >
                  Delete Permanently
                </b-button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" />
            </template>
          </b-pagination>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const postcodeModule = createNamespacedHelpers('postcode')
export default {
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 5,
      pageOptions: [5, 10, 20, 50, 100],
      filter: null,
      fields: [
        'show_details',
        { key: 'ID', label: 'ID' },
        { key: 'group_name', label: 'Group' },
        { key: 'postal_code_group_keys_with_trashed', label: 'Postcodes' },
        { key: 'actions', label: 'Action' },
      ],
      items: [],
    }
  },
  mounted() {
    this.fetchAllDeletedPostcodeGroups()
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...postcodeModule.mapActions(['FETCH_ALL_DELETED_POSTCODE_GROUPS', 'DELETE_POSTCODE_GROUP_PERMANENT', 'RESTORE_POSTCODE_GROUP']),

    async fetchAllDeletedPostcodeGroups() {
      try {
        this.isBusy = true
        this.items = await this.FETCH_ALL_DELETED_POSTCODE_GROUPS()
        this.totalRows = this.items.length
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },
    arrayToString(array) {
      let string = ''
      array.forEach(element => {
        string += `${element.postal_code.code}, `
      })
      string = string.replace(/,\s*$/, '')
      return string
    },
    async deletePostcodeGroupPermanently(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to permanently delete postcode group', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.UPDATE_LOADER(true)
              const resp = await this.DELETE_POSTCODE_GROUP_PERMANENT(item.id)
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Postcode deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Postcode group has been deleted successfully!',
                  },
                })
                this.fetchAllDeletedPostcodeGroups()
              }
              this.UPDATE_LOADER(false)
            } catch (error) {
              this.UPDATE_LOADER(false)
            }
          }
        })
    },
    async restore(item) {
      try {
        this.UPDATE_LOADER(true)
        const resp = await this.RESTORE_POSTCODE_GROUP(item.id)
        if (resp) {
          this.fetchAllDeletedPostcodeGroups()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Postcode restored!',
              icon: 'PlusIcon',
              variant: 'success',
              text: 'Postcode group has been restored successfully!',
            },
          })
        }
        this.UPDATE_LOADER(false)
      } catch (error) {
        this.UPDATE_LOADER(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
